<template>
  <div class="focusList">
    <div class="focusListMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="userItem" v-for="item in list" :key="item.id">
          <router-link
            tag="div"
            :to="'/userHomepage?uid=' + item.uid"
            class="userAvatar"
          >
            <ImgDecypt class="avatarImg" :src="item.coverImg" />
          </router-link>
          <div class="infoBox">
            <div class="userName">{{ item.name }}</div>
            <div class="introduction">
              {{ item.tagDesc || " " }}
            </div>
          </div>
          <!-- <div
            class="focusBtn"
            :class="{ followedBtn: item.hasCollected }"
            @click="careOrcancle(item)"
          >
            {{ item.hasCollected ? "已关注" : " " }}
          </div> -->
          <div
            class="focusBtn"
            :class="{ followedBtn: item.hasCollected }"
            @click="careOrcancle(item)"
          >
            <svg-icon
              class="addIcon"
              icon-class="add"
              v-if="!item.hasCollected"
            ></svg-icon
            >{{ item.isFollow ? "已关注" : " 关注" }}
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryHotspotList } from "@/api/mine";
import { careOrcancle } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { Toast } from "vant";
import { mapGetters } from "vuex";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  computed: {
    ...mapGetters({
      tags: "tags",
    }),
  },
  created() {
    this.getList("");
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        type: "tag",
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
      };
      try {
        let res = await this.$Api(queryHotspotList, req);
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    // 关注-取消关注
    async careOrcancle(item) {
      let req = {
        tagID: item.id,
        isFollow: !item.hasCollected,
      };
      this.$store.commit("app/SET_LOADING", false);
      try {
        let res = await this.$Api(careOrcancle, req);
        console.log(req, item);
        // this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          item.hasCollected = !item.hasCollected;
          Toast(item.hasCollected ? "关注成功" : "取消成功");
          return;
        }
        Toast(req.isFollow ? "关注失败" : "取消失败");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", true);
        Toast("操作失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.focusList {
  height: 100%;
  .focusListMain {
    height: calc(100% - 44px);
    // padding: 18px 12px 0;
    .userItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;

      .userAvatar {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        overflow: hidden;

        /deep/ .vanImage {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background: #130f3200 !important;
        }

        /deep/ .van-image__error {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background-color: #130f3200 !important;
        }

        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      .infoBox {
        .userName {
          font-size: 16px;
          margin-bottom: 6px;
          color: #333333;
        }

        .introduction {
          width: 200px;
          font-size: 14px;
          color: #999999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .focusBtn {
        width: 60px;
        height: 24px;
        // background-image: url("~@/assets/png/followBtn.png");
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        box-sizing: border-box;
        color: #424242;
        background: #fbd13d;
        font-size: 12px;
        border-radius: 12px;
        // color: #94D6DA;
        // border: solid #94D6DA 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        .addIcon {
          width: 11px;
          height: 11px;
        }
      }

      .followedBtn {
        width: 60px;
        height: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: #cccccc;
        font-weight: 500;
        background: #fafafa;
        text-align: center;
      }
    }
  }
}
</style>
